const adsProviders = {
    "adsense": "https://yt3.ggpht.com/ytc/AKedOLSV1ItKw_NWuDeBS4wqrXE0YjmYs_FwAL0DCV598jc=s176-c-k-c0x00ffffff-no-rj",
    "media": "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/eji6nynk1ufunqmy2dze",
    "infolinks": "https://cdn.cybrhome.com/media/website/live/icon/icon_infolinks.com_bot_e40a97.png",
    "propeller": "https://www.growngs.com/images/affiliates/250/propellerads.png",
    "skimlinks": "https://getlasso.co/wp-content/uploads/skimlinks.png",
    "monumentric": "https://d1hbpr09pwz0sk.cloudfront.net/logo_url/monumetric-41123908",
    "popcash": "https://s2.cdnpc.net/front/img/press-kit/web/web-s.png?version=v88",
    "popads": "https://traffcult.com/wp-content/uploads/2019/05/popads_logo-150x150.jpg"
}

export default adsProviders;