const adsProviders = [
    "Google Adsense",
    "Media.Net",
    "Carbon",
    "Infolinks",
    "Propeller Ads",
    "Skimlinks",
    "Columbia Online",
    "Monumentric",
    "PopCash",
    "PopAds",
    "Other"
];

export default adsProviders;